var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"primary--text",attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.setting.name.replaceAll('_', ' ')))]),_c('v-card-text',[_c('p',[_vm._v(_vm._s(_vm.setting.description))]),_vm._t("default")],2),_c('v-card-actions',[_c('BtnWithTooltip',{attrs:{"btnProps":{
        disabled: _vm.setting.default_value == _vm.setting.currentValue,
        icon: false,
        color: 'warning',
        tile: true
      },"btnText":"Default","btnTextSide":"right","iconProps":{ icon: 'mdi-undo', left: true },"tooltipProps":{ color: 'primary', disabled: false, top: true },"tooltipText":("Set to default value (" + (_vm.setting.default_value) + ")")},on:{"click":function($event){return _vm.$emit('updateSetting', {
          setting: _vm.setting.name,
          value: _vm.setting.default_value
        })}}},[_vm._v("Default")]),_c('v-spacer'),_c('BtnWithTooltip',{attrs:{"btnClass":['mx-4'],"btnProps":{
        disabled: _vm.setting.setting == _vm.setting.currentValue,
        icon: false,
        color: 'success',
        tile: true
      },"btnText":"Save","btnTextSide":"right","iconProps":{ icon: 'mdi-content-save', left: true },"tooltipProps":{ color: 'primary', disabled: false, top: true },"tooltipText":'Save Setting'},on:{"click":function($event){return _vm.$emit('saveSetting', _vm.setting.name)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }